<template>
    <v-card  class="overflow-x-hidden b-r-16" style="padding: 20px" max-width="600px" flat>
      <div>
        <v-card-text class="bold-f-s text-center"> Please enter your Debit Card Information
        </v-card-text>
        <v-card-text align="center">
          <a class="f-s-12"
            href="javascript:;"><span>Debit card must be linked to your checking account.</span></a>
        </v-card-text>
        <v-row>
          <v-col cols="12" class="p-t-20">
            <v-card-text>
              <v-row style="padding-left: 11px">
                Card Number:
                <div style="margin-left: 10px">
                  <v-img height="25px" width="40px" src="/static/images/card-visa.png"></v-img>
                </div>
                <div style="float: right;margin-left: 2px">
                  <v-img height="25px" width="40px" src="/static/images/card-mastercard.png"></v-img>
                </div>
              </v-row>
            </v-card-text>
            <v-card-text class="p-t-0">
              <v-text-field
                v-model="instantCard.cardNumber"
                type="number"
              ></v-text-field>
            </v-card-text>
          </v-col>
        </v-row>
        <v-row class="p-t-0 m-t-0">
          <v-col cols="12" class="p-t-0">
            <v-card-text class="p-t-0">Expiration Date:</v-card-text>
            <v-card-text class="p-t-0">
              <v-row>
                <v-col class="p-t-0" cols="7">
                  <v-select
                    :items="mouth"
                    v-model="instantCard.expMonth"></v-select>
                </v-col>
                <v-col class="p-t-0" cols="5">
                  <v-select
                    :items="year"
                    v-model="instantCard.expYear"></v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
        </v-row>
      </div>
      <v-card-actions style="padding-left: 16px">
            <v-btn
              x-large
              block
              color="primary"
              class="width-150"
              @click="submit"
            >Submit
            </v-btn>
      </v-card-actions>
      <v-snackbar multi-line color="primary" v-model="snackbar" centered>
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
        color="error"
        v-model="error.display"
        multi-line
      >
        <v-card-text class="p-0" style="font-size: 16px">{{error.message}}</v-card-text>
        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="error.display = false"
          >
            <v-card-text class="p-0" style="font-size: 26px">Close</v-card-text>
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
</template>
<script>
import { AssistApi } from '../../api/assist'
import { Tool, DataType } from '../../js/core'
const tool = new Tool()

export default {
  name: 'instant-card',
  data: function () {
    return {
      error: {
        display: false
      },
      snackbar: false,
      mouth: [
        { text: '01', value: 1 },
        { text: '02', value: 2 },
        { text: '03', value: 3 },
        { text: '04', value: 4 },
        { text: '05', value: 5 },
        { text: '06', value: 6 },
        { text: '07', value: 7 },
        { text: '08', value: 8 },
        { text: '09', value: 9 },
        { text: '10', value: 10 },
        { text: '11', value: 11 },
        { text: '12', value: 12 }
      ],
      year: [],
      instantCard: {
        cardNumber: '',
        expMonth: '',
        expYear: '',
        loanId: undefined
      },
      loanDetail: {}
    }
  },
  computed: {
    portfolio: {
      get () {
        return this.$store.state.application.portfolioJson
      }
    }
  },
  async created () {
    const year = new Date().getFullYear()
    this.instantCard.expYear = year
    for (let i = 0; i < 10; i++) {
      this.year.push(year + i)
    }
  },
  methods: {
    checkParams () {
      if (tool.isEmpty(this.instantCard.cardNumber) || tool.isEmpty(this.instantCard.expMonth)) {
        const _this = this
        _this.error.message = 'Please make sure the information you are about to submit are accurate and complete.'
        _this.error.display = true
      }
      return tool.isEmpty(this.instantCard.cardNumber) ||
        tool.isEmpty(this.instantCard.expMonth) ||
        tool.isEmpty(localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID)) ||
        tool.isEmpty(this.instantCard.expYear)
    },
    disclaimer () {
    },
    submit () {
      const _this = this
      _this.instantCard.loanId = localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID)
      if (_this.checkParams()) {
        return false
      }
      _this.overlay = true
      AssistApi.createInstantCard(
        _this.instantCard,
        function (result) {
          _this.$emit('submit')
        },
        // eslint-disable-next-line handle-callback-err
        function (error) {
          _this.$emit('submit')
        })
    }
  }
}
</script>
